// TruncatedText.tsx
import React from 'react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';

interface TruncatedTextProps extends TypographyProps {
  text: string;
  maxLength: number;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text, maxLength, ...typographyProps }) => {
  const truncatedText = text.length > maxLength ? `${text.substr(0, maxLength)}...` : text;

  return <>
    <Tooltip title={text}>
      <Typography {...typographyProps}>{truncatedText}</Typography>
    </Tooltip>
    </>
};

export default TruncatedText;