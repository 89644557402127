import * as React from 'react';
import { FC, ReactNode, useContext } from 'react';
import { styled, useTheme, Theme, CSSObject, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

import LogoutIcon from '@mui/icons-material/Logout';
import { AccountCircle } from '@mui/icons-material';
import { Button, Container, Menu, MenuItem } from '@mui/material';



import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { SidebarContext } from '../context/SidebarProvider';
import Scrollbar from '../components/Scrollbar';
import SidebarMenu from './SidebarMenu';
import { useRootStore } from 'context/RootStoreProvider';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1,),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


const drawerWidth = 290;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: 0,
        width: `calc(100% - ${0}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer)(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

type DashBoardProps = {
    appName: string,
    // children: NonNullable<ReactNode>;
};

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
          width: 290px;
          min-width: 290px;
          position: relative;
          z-index: 7;
          height: 100%;
          padding-bottom: 130px;
  `
);

// color: ${theme.colors.alpha.trueWhite[70]};

const SidebarLayout: FC<DashBoardProps> = ({ appName }) => {

    let rootStore = useRootStore()
    const navigate = useNavigate();
    const theme = useTheme();
    //const [open, setOpen] = React.useState(false);


    const { sidebarToggle, toggleSidebar, closeSidebar } = useContext(SidebarContext);

    //const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let initialize = React.useCallback(async () => {
        let email = rootStore.userStore.userEmail
        try {
            if (email) {
                await rootStore.userStore.retrieveUserProfile(email)
                if (rootStore.userStore.currentUser) {
                    
                    await rootStore.linkSummaryStore.retrieveLinkSummaries(rootStore.userStore.currentUser.guid)
                    
                }
            }
        } catch (error) {

        }
    }, [rootStore])

    React.useEffect(() => {
        initialize()

    }, [initialize])
    

    return (
        <>
            <Box sx={{
                display: 'flex', flex: 1, flexGrow: 1,
                margin: 0, padding: 0

            }}>
                <CssBaseline />
                <AppBar position="fixed" open={sidebarToggle}>
                    <Toolbar>
                        {/* <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton onClick={handleDrawerClose}
                        color="inherit"
                        sx={{
                            marginRight: 5,
                            ...(!open && { display: 'none' }),
                        }}
                    >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton> */}
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleSidebar}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                display: {
                                    xs: 'inline-block',
                                    lg: 'none'
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>


                        {/* <IconButton onClick={handleDrawerClose}
                        color="inherit"
                        sx={{
                            marginRight: 5,
                            ...(!open && { display: 'none' }),
                        }}
                    >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton> */}

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            textAlign="left"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            {appName}
                        </Typography>
                        <Box sx={{ display: 'flex' }} >
                            <IconButton color="inherit">
                                <Badge badgeContent={4} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>

                            <Box sx={{ display: 'flex' }}>
                                {/* < Search >
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search> */}
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                </Menu>
                                <IconButton color="inherit" onClick={() => {
                                    if (rootStore.amplifyStore.signOut) {
                                        navigate("/")
                                        rootStore.amplifyStore.signOut()
                                        //return <Navigate to="/" />;
                                    }
                                }

                                }>
                                    <LogoutIcon />
                                </IconButton>
                            </Box>

                        </Box>

                    </Toolbar >
                </AppBar>

                <Drawer variant="temporary" open={sidebarToggle}>
                    <SidebarWrapper>
                        <DrawerHeader>
                            {/* <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton> */}
                        </DrawerHeader>
                        <Divider />

                        <Scrollbar>
                            {/* <Box mt={3}>
                        <Box
                            mx={2}
                            sx={{
                                width: 52
                            }}
                        >
                            <Logo />
                        </Box>
                    </Box> */}
                            <Divider
                                sx={{
                                    mt: theme.spacing(3),
                                    mx: theme.spacing(2),
                                    // background: theme.colors.alpha.trueWhite[10]
                                }}
                            />
                            <SidebarMenu />
                        </Scrollbar>
                        <Divider
                            sx={{
                                // background: theme.colors.alpha.trueWhite[10]
                            }}
                        />
                        <Box p={2}>
                            <Button
                                href="mailto:hi@catrocket"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="contained"
                                color="warning"
                                size="small"
                                fullWidth
                            >
                                Support
                            </Button>
                        </Box>
                    </SidebarWrapper>
                </Drawer>

                <Box width="290px" sx={{
                    display: {
                        xs: 'none',
                        lg: 'flex'

                    },
                    left: 0,
                    top: 0,
                    position: "fixed",
                    height: '100%',

                }} flexDirection="column">
                    <Toolbar />
                    <Scrollbar>
                        {/* <Box mt={3}>
                        <Box
                            mx={2}
                            sx={{
                                width: 52
                            }}
                        >
                            <Logo />
                        </Box>
                    </Box> */}
                        <Divider
                            sx={{
                                mt: theme.spacing(3),
                                mx: theme.spacing(2),
                                // background: theme.colors.alpha.trueWhite[10]
                            }}
                        />
                        <SidebarMenu />


                    </Scrollbar>
                    <Divider
                        sx={{
                            // background: theme.colors.alpha.trueWhite[10]
                        }}
                    />
                    <Box p={2}>
                        <Button
                            href="mailto:hi@catrocket"
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="contained"
                            color="warning"
                            size="small"
                            fullWidth
                        >
                            Support
                        </Button>
                    </Box>

                </Box>


                <Box

                    sx={{
                        position: 'relative',

                        zIndex: 5,
                        display: 'block',
                        flex: 1,
                        pt: '80px',
                        [theme.breakpoints.up('lg')]: {
                            ml: '290px'
                        }
                    }}
                >
                    <Box display="block">
                        <Outlet />
                    </Box>
                </Box>
            </Box >
        </>
    );
}

export default SidebarLayout
