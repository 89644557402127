import { action, makeObservable, observable } from "mobx";
import { IClearableStore } from "./StoreHelper";


class UiStore implements IClearableStore {

    @observable
    urlCreateLinkSummary: string = ''

    

    constructor() {
        makeObservable(this)
        this.urlCreateLinkSummary = ''
    }

    @action
    setUrlCreateLinkSummary(url: string) {
        this.urlCreateLinkSummary = url
    }

    @action
    resetUrlCreateLinkSummary() {
        this.urlCreateLinkSummary = ''
    }

    get UrlCreateLinkSummary(): string {
        return this.urlCreateLinkSummary
    }

    clear(): void {
        this.urlCreateLinkSummary = ''
    }
}

export {
    UiStore
}