import { LinkSummaryService } from "service/LinkPreviewService";
import { ILinkSummary, LinkSummary } from "types";
import { IClearableStore } from "./StoreHelper";

import { action, makeObservable, observable, computed, makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";

export class LinkSummaryStore implements IClearableStore {

    linkSummaryService: LinkSummaryService

    @observable
    linkSummaries: Array<ILinkSummary>

    constructor(private rootStore: RootStore) {
        makeObservable(this)
        this.linkSummaryService = new LinkSummaryService()
        this.linkSummaries = []
    }

    @action
    async retrieveLinkSummaries(userId: string) {
        try {
            let linkSummaries = await this.linkSummaryService.retrieveLinkSummaries(userId)
            if (linkSummaries) {
                console.log('###')
                console.log(linkSummaries)
                this.setLinkSummaries(linkSummaries)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async createLinkSummary(userId: string, url: string) {
        try {
            let linkSummaries = await this.linkSummaryService.createLinkSummaries(userId, url)
            console.log('linksummary created')
            console.log('###')
            console.log(linkSummaries)
            await this.retrieveLinkSummaries(userId)

        } catch (error) {
            console.log(error)
        }
    }

    @action
    async deleteLinkSummary(userId: string, linkSummaryId: string) {
        try {
            let linkSummaries = await this.linkSummaryService.deleteLinkSummaries(userId, linkSummaryId)
            console.log('linksummary deleted')
            console.log(linkSummaries)
            await this.retrieveLinkSummaries(userId)
        } catch (error) {
            console.log(error)
        }
    }

    @action
    async markLinkSummaryUnraed(userId: string, linkSummaryId: string, unread: boolean) {
        try {
            let linkSummaries = await this.linkSummaryService.markLinkSummaryUnread(userId, linkSummaryId, unread)
            console.log('linksummary marked')
            console.log(linkSummaries)
            await this.retrieveLinkSummaries(userId)
        } catch (error) {
            console.log(error)
        }
    }

    getSelectedLinkSummaryById(id: string) : ILinkSummary | undefined{
        for (let idx in this.linkSummaries) {
            let linkSummary = this.linkSummaries[idx]
            if (id === linkSummary.guid) {
                return this.linkSummaries[idx]
            }
        }

        return undefined
    }


    @action
    setLinkSummaries(linkSummaries: Array<ILinkSummary>) {
        this.linkSummaries = linkSummaries
    }

    clear(): void {
        this.linkSummaryService = new LinkSummaryService()
        this.linkSummaries = []
    }
}