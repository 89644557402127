import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from 'components/Copyright';
import { FormControl, IconButton, InputLabel, OutlinedInput, Paper } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsightsIcon from '@mui/icons-material/Insights';
import { borderRadius, width } from '@mui/system';
import { useRootStore } from 'context/RootStoreProvider';
import { ILinkSummary } from 'types';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const LinkSummaryCreate = observer(() => {

    // export default function LinkSummaryCreate() {

    const rootStore = useRootStore()
    const navigate = useNavigate()


    const createLinkSummary = async () => {

        if (rootStore.userStore.currentUser) {
            let userId = rootStore.userStore.currentUser.guid
            let url = rootStore.uiStore.UrlCreateLinkSummary
            await rootStore.linkSummaryStore.createLinkSummary(userId, url)
            let targetRoute = "/linksummaries"
            navigate(targetRoute)
            rootStore.uiStore.resetUrlCreateLinkSummary()
        }
    }

    const onUrlCreateLinkSummaryChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        rootStore.uiStore.setUrlCreateLinkSummary(event.target.value)
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                my: 1,
                                mx: 0,
                                py: 1,
                                px: 0,
                                display: 'flex',
                                flexGrow: 1,
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography component="h1" textAlign="left" variant="h5">
                                Create a new Link Summary
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box

                            component="form"
                            noValidate
                            autoComplete="off"
                            sx={{
                                my: 1,
                                mx: 0,
                                py: 1,
                                px: 0,
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <FormControl fullWidth>
                                <InputLabel htmlFor="url-textfield">Url</InputLabel>
                                <OutlinedInput
                                    id="url-textfield"
                                    label="Url"
                                    value={rootStore.uiStore.UrlCreateLinkSummary}
                                    onChange={onUrlCreateLinkSummaryChanged}
                                />
                            </FormControl>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box
                            sx={{
                                my: 1,
                                mx: 0,
                                py: 1,
                                px: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Button variant="contained" onClick={createLinkSummary}>
                                Create</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
})

export default LinkSummaryCreate