import blogPostData from '../../mockdata/blogpost.json';

import React, { useEffect } from 'react';
import { Box, Typography, Paper, Avatar, Chip } from '@mui/material';
import { SxProps } from '@mui/system';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';

type BlogPostProps = {
  post: any; // Replace 'any' with the type of your blog post object
};

const sectionStyle: SxProps = {
  marginBottom: 4,
};

const contentStyle: SxProps = {
  marginTop: 2,
  marginBottom: 2,
};

const BlogPost: React.FC<BlogPostProps> = ({ post }) => {

  useEffect( () => {
    SyntaxHighlighter.registerLanguage('javascript', js);
    SyntaxHighlighter.registerLanguage('python', python);
  })

  const renderContent = (contentItem: any) => {
    switch (contentItem.type) {
      case 'text':
        return <Typography sx={{ textAlign: 'left'}}>{contentItem.data}</Typography>;
      case 'image':
        return <img src={contentItem.data} alt="Content" style={{ maxWidth: '100%' }} />;
      case 'code':
        
        return (
          <Box display="flex" flexDirection="row" justifyContent="left" alignItems="center" sx={{ marginTop: 2, marginBottom: 4, textAlign: 'left',  flexGrow: 1, width: '100%' }}>
            <SyntaxHighlighter language={contentItem.properties.language} style={docco}>
              {contentItem.data}
            </SyntaxHighlighter>
          </Box>
        );
      case 'quote':
        return (
          <Paper sx={{ padding: 2, backgroundColor: 'rgba(0,0,0,0.03)', fontStyle: 'italic' }}>
            <Typography component="blockquote" variant="body1">
              &ldquo;{contentItem.data}&rdquo;
            </Typography>
          </Paper>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography variant="h3" component="h1">
        {post.title}
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ marginTop: 2, marginBottom: 4 }}>
        <Avatar src={post.author.avatar_url} alt={post.author.name} />
        <Typography sx={{ marginLeft: 2 }}>{post.author.name}</Typography>

      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ marginTop: 2, marginBottom: 4 }}>
        
      <Typography variant="body2" color="text.secondary">
        Updated on {new Date(post.updated_at).toLocaleDateString()}
      </Typography>
        
      </Box>
      {post.sections.map((section: any) => (
        <Box key={section._id} sx={sectionStyle}>
          <Typography variant="h5" component="h2" textAlign={'left'}>
            {section.title}
          </Typography>
          {section.content.map((contentItem: any) => (
            <Box key={contentItem._id} sx={contentStyle}>
              {renderContent(contentItem)}
            </Box>
          ))}
        </Box>
      ))}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" component="h2">
          Comments
        </Typography>
        {post.comments.map((comment: any) => (
          <Box key={comment._id} sx={{ marginTop: 2 }}>
            <Paper sx={{ padding: 2 }}>
              <Box display="flex" alignItems="center">
                <Avatar src={comment.author.avatar_url} alt={comment.author.name} />
                <Typography sx={{ marginLeft: 2 }}>{comment.author.name}</Typography>
              </Box>
              <Typography sx={{ marginTop: 1 }}>{comment.content}</Typography>
            </Paper>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BlogPost;