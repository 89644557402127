import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from 'components/Copyright';
import { Autocomplete, AutocompleteChangeDetails, Chip, IconButton, Paper, Stack } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsightsIcon from '@mui/icons-material/Insights';
import { borderRadius, width } from '@mui/system';
import { useRootStore } from 'context/RootStoreProvider';
import { ILinkSummary } from 'types';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';


const LinkSummaryOverview = observer(() => {

    const rootStore = useRootStore()
    const navigate = useNavigate()

    const createLinkSummary = () => {
        navigate("/create")
    }

    let initialize = React.useCallback(async () => {
        try {
            await rootStore.userStore.retrieveUserOverview()
            if (rootStore.userStore.currentUser) {
                
            }
        
        } catch (error) {

        }
    }, [rootStore])

    React.useEffect(() => {
        initialize()

    }, [initialize])

    const options = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' };

    
      //console.log(formattedDate);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {rootStore.userStore.userOverview &&
                    <Grid container>
                        <Grid item xs={12}>
                        <Typography variant="h5">Overview</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">Link Summaries</Typography>
                            <Typography variant="h6">{rootStore.userStore.userOverview.count}</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">Most Recent Date</Typography>
                            <Typography variant="h6">{rootStore.userStore.userOverview.mostRecentDate.toLocaleDateString('de-DE')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">Oldest Link Summary</Typography>
                            <Typography variant="h6">{rootStore.userStore.userOverview.oldestSummary.toLocaleDateString('de-DE')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">Latest Modification</Typography>
                            <Typography variant="h6">{rootStore.userStore.userOverview.latestModification.toLocaleDateString('de-DE')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="subtitle1">Latest Modification</Typography>
                            <Typography variant="h6">{rootStore.userStore.userOverview.latestModification.toLocaleDateString('de-DE')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Button variant="contained" onClick={createLinkSummary}>
                                    Create Link Summary</Button>
                            </Box>
                        </Grid>
                        
                        {/* <Grid item xs={12}>
                            <Paper
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-start', m: 1}}>
                                            
                                                <Typography textAlign="left" fontWeight={"bold"} variant="body1">
                                                    Summary:  
                                                </Typography>
                                                <Typography>
                                                    {' '}
                                                </Typography>
                                                <Typography textAlign="left" variant="body1">
                                                    {rootStore.linkSummaryDetailStore.summary}
                                                </Typography>
                                            
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-start', m: 1}}>
                                            <Button size={"small"} variant={"outlined"} onClick={updateSummary}> Update</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>

                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            disableClearable
                                            id="multiple-limit-tags"
                                            options={rootStore.linkSummaryDetailStore.keywords}
                                            getOptionLabel={(option) => option}
                                            value={rootStore.linkSummaryDetailStore.keywords}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField {...params} label="limitTags" placeholder="Favorites" />
                                            )}
                                            sx={{ width: '100%' }}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-start', m: 1}}>
                                            <Button size={"small"} variant={"outlined"} onClick={updateKeywords}> Update</Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                                </Paper>
                                </Grid>
                         */}

                    </Grid>
                }
            </Box>
        </Container>
    );
})

export {
    LinkSummaryOverview
}