import { useContext } from 'react';

import {
    ListSubheader,
    alpha,
    Box,
    List,
    styled,
    Button,
    ListItem,
    Divider,
    Typography
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from '../../context/SidebarProvider';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ChromeReaderModeTwoToneIcon from '@mui/icons-material/ChromeReaderModeTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import CameraFrontTwoToneIcon from '@mui/icons-material/CameraFrontTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';

import SettingsIcon from '@mui/icons-material/Settings';
import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DatasetIcon from '@mui/icons-material/Dataset';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';

const MenuWrapper = styled(Box)(
    ({ theme }) => `
        .MuiList-root {
        padding: ${theme.spacing(1)};

        & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
        }
    }`
);

const StyledButton = styled(Button)(`
    text-transform: none;
`);


function SidebarMenu() {
    const { closeSidebar } = useContext(SidebarContext);

    const menuEntries = [
        {
            title: "Link Management",
            subMenu: [
                {
                    title: "Link Summaries",
                    routerLink: RouterLink,
                    onClick: closeSidebar,
                    targetRoute: "/linksummaries",
                    icon: <DatasetIcon />
                },
                {
                    title: "Create New",
                    routerLink: RouterLink,
                    onClick: closeSidebar,
                    targetRoute: "/create",
                    icon: <DatasetIcon />
                }
            ]
        }
    ]

    return (
        <>
            <MenuWrapper>
                <List dense>
                    <List dense>
                        <ListItem dense>
                            <Button

                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/overview"
                                startIcon={<DesignServicesTwoToneIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Overview
                            </Button>
                        </ListItem>
                    </List>
                </List>

                {
                    menuEntries.length > 0 && menuEntries.map((menuEntry: any, index: number) => {
                        return (
                            <List
                                key={`${menuEntry.title}-${index}`}
                                subheader={
                                    <ListSubheader component="div" disableSticky>
                                        <Typography textAlign="left" variant='body1'>{menuEntry.title}</Typography>
                                    </ListSubheader>
                                }
                            >
                                <List dense>

                                    {
                                        menuEntry.subMenu.length > 0 && menuEntry.subMenu.map((subMenu: any, index: number) => {
                                            return (
                                                <ListItem
                                                    key={`${subMenu.title}-${index}`}
                                                    dense>
                                                    <Button
                                                        disableRipple
                                                        component={subMenu.routerLink}
                                                        onClick={subMenu.onClick}
                                                        to={subMenu.targetRoute}
                                                        startIcon={subMenu.icon}
                                                        sx={{
                                                            'text-transform': 'none'
                                                        }}
                                                    >
                                                        {subMenu.title}
                                                    </Button>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </List>
                        )
                    })
                }

                {/* <List
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            <Typography textAlign="left" variant='body1'>Link Management</Typography>
                        </ListSubheader>
                    }
                >
                    <List dense>

                        <ListItem dense>
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/linksummaries"
                                startIcon={<DatasetIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Link Summaries
                            </Button>
                        </ListItem>
                        <ListItem dense>
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/create"
                                startIcon={<DatasetIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Create
                            </Button>
                        </ListItem>
                    </List>
                </List> */}

                {/* <List
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            <Typography textAlign="left" variant='body1'>Analytics</Typography>
                        </ListSubheader>
                    }
                >
                    <List dense>
                        <ListItem dense>
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/boxes"
                                startIcon={<OnlinePredictionIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Live Models
                            </Button>
                        </ListItem>
                        <ListItem dense>
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/boxes"
                                startIcon={<NotificationsIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Notifications
                            </Button>
                        </ListItem>
                        <ListItem dense>
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/boxes"
                                startIcon={<DashboardIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Dashboards
                            </Button>
                        </ListItem>
                    </List>
                </List> */}

                <List
                    subheader={
                        <ListSubheader component="div" disableSticky>
                            <Typography textAlign="left" variant='body1'>Support</Typography>
                        </ListSubheader>
                    }
                >
                    <List dense>
                        <ListItem dense>
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/boxes"
                                startIcon={<ArticleIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Documentation
                            </Button>
                        </ListItem>
                        <ListItem dense>
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/boxes"
                                startIcon={<SettingsIcon />}
                                sx={{
                                    'text-transform': 'none'
                                }}
                            >
                                Settings
                            </Button>
                        </ListItem>


                    </List>
                </List>
                {/* <List>
                    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/typo"
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: 1 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {['All mail', 'Trash', 'Spam'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                }}
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/boxes"

                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List> */}
            </MenuWrapper>
        </>
    );
}

export default SidebarMenu;
