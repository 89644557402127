import { Flex, Text, View } from "@aws-amplify/ui-react";
import { useRootStore } from "context/RootStoreProvider";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { ILinkSummary } from "types";

import routes from "../../router"

import { AuthEventData } from "@aws-amplify/ui";

import { useRoutes } from 'react-router-dom';
import { SidebarProvider } from "context/SidebarProvider";

interface MainLayoutProperties {
    email?: string,
    signOut: ((data?: AuthEventData | undefined) => void) | undefined
}

const MainLayout = observer(({ email, signOut }: MainLayoutProperties) => {

    let rootStore = useRootStore()
    const content = useRoutes(routes);

    useEffect(() => {
        rootStore.amplifyStore.signOut = signOut
        rootStore.userStore.userEmail = email
    }, [rootStore])

    


    return (
        <SidebarProvider>

            {/* {rootStore.userStore.currentUser &&
                <Flex
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="flex-start"
                    wrap="nowrap"
                    gap="1rem"
                    textAlign="center"
                >

                    <View width="100%">
                        <Text>{rootStore.userStore.currentUser.name}</Text>
                    </View>
                    {rootStore.linkSummaryStore.linkSummaries.length > 0 && rootStore.linkSummaryStore.linkSummaries.map((linkSummary: ILinkSummary, index: number) => {
                        return (< Text key={index}> {linkSummary.title}</Text>)
                    }
                    )}
                </Flex>




            } */}
            {content}


        </SidebarProvider >

    )
})

export {
    MainLayout
}