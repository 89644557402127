
import { API, Auth } from "aws-amplify";
import { Constants } from "helper/Constants";
import { ILinkSummary, LinkSummary } from "types";


export class LinkSummaryService {

    constructor() {

    }

    async retrieveLinkSummaries(userId: string): Promise<Array<ILinkSummary> | undefined> {

        const apiName = 'helloworld';
        const path = `/user/${userId}/linksummary`;
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                "user_id": userId
            }
        };

        try {
            let result = await API.get(apiName, path, myInit);
            let linkSummaries = result[Constants.LINK_SUMMARIES_RESPONSE]
            let mappedSummaries = linkSummaries.map((linkSummary: any) => {
                let newLinkSummary = LinkSummary.FromJson(linkSummary)
                console.log(newLinkSummary)
                return newLinkSummary
            })
            return new Promise((resolve, reject) => {
                resolve(mappedSummaries)
            })
        } catch (error) {

        }

        return new Promise((resolve, reject) => {
            resolve(undefined)
        })
    }

    async createLinkSummaries(userId: string, url: string): Promise<boolean> {

        const apiName = 'helloworld';
        const path = `/user/${userId}/linksummary/create`;
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                "url": url
            }
        };

        try {
            let result = await API.post(apiName, path, myInit);
            console.log(result)

            return new Promise((resolve, reject) => {
                resolve(true)
            })
        } catch (error) {
            return new Promise((resolve, reject) => {
                resolve(false)
            })
        }
    }

    async deleteLinkSummaries(userId: string, linkSummaryId: string): Promise<boolean> {

        const apiName = 'helloworld';
        const path = `/user/${userId}/linksummary/${linkSummaryId}`;
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            }
        };

        try {
            let result = await API.del(apiName, path, myInit);
            console.log(result)

            return new Promise((resolve, reject) => {
                resolve(true)
            })
        } catch (error) {
            return new Promise((resolve, reject) => {
                resolve(false)
            })
        }
    }

    async markLinkSummaryUnread(userId: string, linkSummaryId: string, unread: boolean): Promise<boolean> {
        console.log('post', unread)
        const apiName = 'helloworld';
        const path = `/user/${userId}/linksummary/${linkSummaryId}/unread`;
        console.log(path)
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                "unread": unread
            }
        };

        try {
            let result = await API.post(apiName, path, myInit);
            console.log(result)

            return new Promise((resolve, reject) => {
                resolve(true)
            })
        } catch (error) {
            return new Promise((resolve, reject) => {
                resolve(false)
            })
        }
    }

    async generateSummary(userId: string, linkSummaryId: string) : Promise<string | undefined> { 
        const apiName = 'helloworld';
        const path = `/user/${userId}/linksummary/${linkSummaryId}/summary`;
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                "parameters": "asdf"
            }
        };

        try {
            let result = await API.post(apiName, path, myInit);
            console.log(result)

            return new Promise((resolve, reject) => {
                resolve(result["summary"])
            })
        } catch (error) {
            return new Promise((resolve, reject) => {
                resolve(undefined)
            })
        }
    }

    async generateKeywords(userId: string, linkSummaryId: string) : Promise<string [] | undefined> { 
        const apiName = 'helloworld';
        const path = `/user/${userId}/linksummary/${linkSummaryId}/extract_keywords`;
        const myInit = {
            
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                "parameters": "asdf"
            }
        };

        try {
            let result = await API.post(apiName, path, myInit);
            console.log(result)

            return new Promise((resolve, reject) => {
                resolve(result["keywords"])
            })
        } catch (error) {
            return new Promise((resolve, reject) => {
                resolve(undefined)
            })
        }
    }

    async updateKeywords(userId: string, linkSummaryId: string, keywords: string []) : Promise<boolean> { 
        const apiName = 'helloworld';
        const path = `/user/${userId}/linksummary/${linkSummaryId}/update_keywords`;

        let keyword_object = Object.assign(keywords)

        const myInit = {
            
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                "keywords": keyword_object
            }
        };

        console.log(keyword_object)
        try {
            let result = await API.post(apiName, path, myInit);
            console.log(result)

            return new Promise((resolve, reject) => {
                resolve(true)
            })
        } catch (error) {
            return new Promise((resolve, reject) => {
                resolve(false)
            })
        }
    }
}