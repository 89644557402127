import { AuthEventData } from "@aws-amplify/ui";
import { AmplifyStore } from "./AmplifyStore";
import { LinkSummaryStore } from "./LinkPreviewStore";
import { LinkSummaryDetailStore } from "./LinkSummaryDetailStore";
import { IClearableStore } from "./StoreHelper";
import { UiStore } from "./UiStore";
import { UserStore } from "./UserStore";


export class RootStore implements IClearableStore {

    userStore: UserStore
    linkSummaryStore: LinkSummaryStore
    linkSummaryDetailStore: LinkSummaryDetailStore
    uiStore: UiStore
    amplifyStore: AmplifyStore
    //signOut: ((data?: AuthEventData | undefined) => void) | undefined

    constructor() {
        this.userStore = new UserStore()
        this.linkSummaryStore = new LinkSummaryStore(this)
        this.linkSummaryDetailStore = new LinkSummaryDetailStore(this)
        this.uiStore = new UiStore()
        this.amplifyStore = new AmplifyStore()
        //this.signOut = undefined
    }

    clear(): void {
        this.userStore.clear()
        this.linkSummaryStore.clear()
        this.linkSummaryDetailStore.clear()
        this.uiStore.clear()
        this.amplifyStore.clear()
    }
}