import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from 'components/Copyright';
import { Autocomplete, AutocompleteChangeDetails, Chip, IconButton, Paper, Stack } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsightsIcon from '@mui/icons-material/Insights';
import { borderRadius, width } from '@mui/system';
import { useRootStore } from 'context/RootStoreProvider';
import { ILinkSummary } from 'types';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

const top100Films = [
   "test 1",
   "test 2"
]


const LinkSummaryDetails = observer(() => {

    const rootStore = useRootStore()
    const navigate = useNavigate()

    const createLinkSummary = () => {
        navigate("/create")
    }

    const updateSummary = () => {

        if (rootStore.linkSummaryDetailStore._selectedLinkSummary) {
            rootStore.linkSummaryDetailStore.updateSummary(rootStore.linkSummaryDetailStore._selectedLinkSummary.guid)
            console.log('update summary')
            console.log(rootStore.linkSummaryDetailStore._selectedLinkSummary)
        }
    }

    const updateKeywords = () => {
        if (rootStore.linkSummaryDetailStore._selectedLinkSummary) {
            rootStore.linkSummaryDetailStore.updateKeywords(rootStore.linkSummaryDetailStore._selectedLinkSummary.guid)
            console.log('update updateKeywords')
            console.log(rootStore.linkSummaryDetailStore._selectedLinkSummary)

        
        }
    }

    const onChange = (event: React.SyntheticEvent, value: string | Array<string>, reason: string, details?: AutocompleteChangeDetails<string>) => {
        console.log(details?.option)
        console.log(reason)
        if (reason === 'createOption') {
            if (details) {
                rootStore.linkSummaryDetailStore.addKeyword(details?.option)
                rootStore.linkSummaryDetailStore.updateLinkSummaryKeywords()
            }
            
        } else if (reason === 'removeOption') {
            if (details) {
                let array = rootStore.linkSummaryDetailStore._keywords.filter((value: string, index: number) => {
                    return value !== details.option
                })

                rootStore.linkSummaryDetailStore.setKeywords(array)
                rootStore.linkSummaryDetailStore.updateLinkSummaryKeywords()
            }
        }
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {rootStore.linkSummaryDetailStore._selectedLinkSummary &&
                    <Grid container>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center'
                                }}
                            >
                                <Box sx={{
                                    // bgcolor: '#888',
                                    // borderRadius: '10px',
                                    // width: '50%'
                                }}>
                                    {rootStore.linkSummaryDetailStore._selectedLinkSummary.unread === true &&
                                        <IconButton onClick={() => {
                                            //handleMarkUnRead(rootStore.linkSummaryStore.selectedLinkSummary.guid, false)
                                        }}>
                                            <BookmarkIcon />
                                        </IconButton>
                                    }
                                    {rootStore.linkSummaryDetailStore._selectedLinkSummary.unread === false &&
                                        <IconButton onClick={() => {
                                            //handleMarkUnRead(rootStore.linkSummaryStore.selectedLinkSummary.guid, true)
                                        }}>
                                            <BookmarkBorderIcon />
                                        </IconButton>
                                    }
                                </Box>
                                <Typography component="h1" textAlign="left" variant="h5">
                                    {rootStore.linkSummaryDetailStore._selectedLinkSummary.title}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-start', m: 1}}>
                                            
                                                <Typography textAlign="left" fontWeight={"bold"} variant="body1">
                                                    Summary:  
                                                </Typography>
                                                <Typography>
                                                    {' '}
                                                </Typography>
                                                <Typography textAlign="left" variant="body1">
                                                    {rootStore.linkSummaryDetailStore.summary}
                                                </Typography>
                                            
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-start', m: 1}}>
                                            <Button size={"small"} variant={"outlined"} onClick={updateSummary}> Update</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>

                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            disableClearable
                                            id="multiple-limit-tags"
                                            options={rootStore.linkSummaryDetailStore.keywords}
                                            getOptionLabel={(option) => option}
                                            value={rootStore.linkSummaryDetailStore.keywords}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField {...params} label="limitTags" placeholder="Favorites" />
                                            )}
                                            sx={{ width: '100%' }}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'flex-start', m: 1}}>
                                            <Button size={"small"} variant={"outlined"} onClick={updateKeywords}> Update</Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                                </Paper>
                                </Grid>
                        

                        {/* <Grid item xs={12}>
                            <Paper
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'left',
                                    alignItems: 'center'

                                }}
                            >
                                {
                                    rootStore.linkSummaryStore.selectedLinkSummary.keywords.map((keyword: string, index: number) => {
                                        return (<Chip sx={{ m: 1 }} key={keyword} label={keyword} color={"primary"}></Chip>)
                                    })
                                }
                            </Paper>
                        </Grid> */}

                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    m: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Button variant="contained" onClick={createLinkSummary}>
                                    Create Link Summary</Button>
                            </Box>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Container>
    );
})

export {
    LinkSummaryDetails
}