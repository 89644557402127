import { resolve } from "path";
import { UserService } from "service/UserService";
import { IUser, IUserOverview } from "types";
import { IClearableStore } from "./StoreHelper";

import { action, makeObservable, observable, computed } from "mobx";


export class UserStore implements IClearableStore {

    @observable
    currentUser: IUser | undefined

    @observable
    userOverview: IUserOverview | undefined

    userService: UserService

    _userEmail: string | undefined

    constructor() {
        makeObservable(this)
        this.currentUser = undefined
        this.userOverview = undefined
        this.userService = new UserService()
        this._userEmail = undefined
    }

    get userEmail() : string | undefined{
        return this._userEmail
    }

    set userEmail(email: string | undefined)  {
        this._userEmail = email
    }


    async retrieveUserProfile(email: string) {
        let user: IUser | undefined = await this.userService.retrieveUserProfile(email)
        this.setCurrentUser(user)
        console.log(this.currentUser)
    }

    async retrieveUserOverview() {

        if (this.currentUser) {
            let userOverview: IUserOverview | undefined = await this.userService.retrieveUserOverview(this.currentUser.guid)
            this.setUserOverview(userOverview)
            console.log(this.userOverview)
        }
    }

    @action
    setCurrentUser(user: IUser | undefined) {
        this.currentUser = user
    }

    @action
    setUserOverview(userOverview: IUserOverview | undefined) {
        this.userOverview = userOverview
    }

    hasCurrentUser(): boolean {
        return this.currentUser !== undefined
    }

    @action
    clear(): void {
        this.currentUser = undefined
        this.userOverview = undefined
        this.userEmail = undefined
    }
}