

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from './dashboard/dashboard';
import TypographPage from './components/typegraphies';
import BoxesPage from './components/boxes';
import { LinkSummaryList } from 'components/LinkSummaries/LinkSummaryList';
import { LinkSummaryCreate } from 'components/LinkSummaries/LinkSummaryCreate';
import { LinkSummaryDetails } from 'components/LinkSummaries/LinkSummaryDetails';
import { LinkSummaryOverview } from 'components/LinkSummaries/Overview';
import { BlogLayout } from 'components/BlogLayout';

// import SignUp from 'components/Account/SignUp';
// import SignIn from 'components/Account/SignIn';
// import PasswordReset from 'components/Account/PasswordReset';
// import ActiveProjects from 'specific_components/projects/ActiveProjects';

const routes: RouteObject[] = [
    {
        path: '/',
        element: <SidebarLayout appName="Link Summaries" />,
        children: [
            // {
            //     path: '/',
            //     element: <Navigate to="active_projects" replace />
            // },
            {
                path: '/typo',
                element: <TypographPage />
            },
            {
                path: '/overview',
                element: <LinkSummaryOverview />
            },
            {
                path: '/linksummaries',
                element: <LinkSummaryList />
            },
            {
                path: '/create',
                element: <LinkSummaryCreate />
            },
            {
                path: '/details',
                element: <LinkSummaryDetails />
            }
        ]
    },
    {
        path: '/blog/:blogId',
        element: <BlogLayout />
    }

    // {
    //     path: '/signup',
    //     element: <SignUp />
    // },
    // {
    //     path: '/signin',
    //     element: <SignIn />
    // },
    // {
    //     path: '/resetpassword',
    //     element: <PasswordReset />
    // },

]

export default routes;