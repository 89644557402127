import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from 'components/Copyright';
import { Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, IconButtonProps, Paper, styled } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsightsIcon from '@mui/icons-material/Insights';
import { borderRadius, width } from '@mui/system';
import { useRootStore } from 'context/RootStoreProvider';
import { ILinkSummary } from 'types';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { LinkSharp, MoreVertOutlined } from '@mui/icons-material';
import { red } from '@mui/material/colors';

import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TruncatedText } from 'components/TruncatedText';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


type ActionsActiveProject = {
    icon: React.ReactNode
    actionType: string
    onClick: (type: string, id: string) => void
}

interface LinkSummaryListItemProps {
    item: ILinkSummary
}

const LinkSummaryListItem : React.FC<LinkSummaryListItemProps>= (props) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const rootStore = useRootStore()
    const navigate = useNavigate()

    const handleAction = (type: string, id: string) => {
        console.log(id)
        if (type === 'delete') {
            if (rootStore.userStore.currentUser) {
                let userId = rootStore.userStore.currentUser.guid
                rootStore.linkSummaryStore.deleteLinkSummary(userId, id)
            }
        } else if (type === 'edit') {
            console.log(id)
            let linkSummary = rootStore.linkSummaryStore.getSelectedLinkSummaryById(id)
            if (linkSummary) {
                // TODO: do we need to update the link summary within the array

                rootStore.linkSummaryDetailStore.setLinkSummary(linkSummary)
            }
            navigate('/details')
        }
    }

    const handleMarkUnRead = (id: string, value: boolean) => {
        if (rootStore.userStore.currentUser) {
            let userId = rootStore.userStore.currentUser.guid
            rootStore.linkSummaryStore.markLinkSummaryUnraed(userId, id, value)
        }
    }


    const actionProjectActions: ActionsActiveProject[] = [
        // {
        //     icon: <InsightsIcon />,
        //     actionType: 'insight',
        //     onClick: handleAction
        // },
        {
            icon: <EditIcon />,
            actionType: 'edit',
            onClick: handleAction
        },
        {
            icon: <DeleteIcon />,
            actionType: 'delete',
            onClick: handleAction
        }
    ]

    let title = <TruncatedText text={props.item.title} maxLength={50} variant='subtitle1' textAlign="left" fontWeight="bold" /> 
    if (props.item.unread === false) {
        title = <TruncatedText text={props.item.title} maxLength={50} variant='subtitle1' textAlign="left" /> 
    }

    let date = <Typography variant='subtitle1' textAlign="left" ></Typography>
    if (props.item.date) {
        date = <Typography variant='subtitle1' textAlign="left">
            {new Date(props.item.date).toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
    }

    return (
        <Card sx={{height: '100%'}} >
            <CardHeader
                avatar={
                    <CardMedia
                        component="img"
                        sx={{ width: "96px" }}
                        image={props.item.image}
                        alt="Live from space album cover"
                    />
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={
                    title
                }
                subheader={
                    date
                }
            />

            <CardActions disableSpacing>
                {actionProjectActions.map((action, index) => {
                    return (<IconButton key={index} color="primary" aria-label="add to shopping cart" onClick={() => {
                        action.onClick(action.actionType, props.item.guid)
                    }}>
                        {action.icon}
                    </IconButton>)
                })}
                <Box sx={{

                }}>
                    {props.item.unread === true &&
                        <IconButton onClick={() => {
                            handleMarkUnRead(props.item.guid, false)
                        }}>
                            <BookmarkIcon />
                        </IconButton>
                    }
                    {props.item.unread === false &&
                        <IconButton onClick={() => {
                            handleMarkUnRead(props.item.guid, true)
                        }}>
                            <BookmarkBorderIcon />
                        </IconButton>
                    }

                    <IconButton href={props.item.url} target="_blank">
                        <LinkSharp></LinkSharp>
                    </IconButton>
                    
                    
                </Box>

                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>

            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" textAlign="left">
                        Summary
                    </Typography>
                    <Typography paragraph color="text.secondary" textAlign="left">
                        {props.item.summary}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

const ObservedLinkSummaryListItem = observer(LinkSummaryListItem)

export {
    ObservedLinkSummaryListItem
}