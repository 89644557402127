import React from 'react';

import { Container } from '@mui/material';
import blogPostData from '../../mockdata/blogpost.json';
import BlogPost from 'components/BlogPost/BlogPost';
import { useParams } from 'react-router-dom';

const BlogLayout: React.FC = () => {

  const params = useParams()
  console.log(params.blogId)
  return (
    <Container maxWidth="md">
      <BlogPost post={blogPostData} />
    </Container>
  );
};

export default BlogLayout;