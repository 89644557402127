import { IClearableStore } from "./StoreHelper";
import { AuthEventData } from "@aws-amplify/ui";

class AmplifyStore implements IClearableStore {

    signOut: ((data?: AuthEventData | undefined) => void) | undefined

    constructor() {
        this.signOut = undefined
    }

    clear() {

    }
}

export {
    AmplifyStore
}