import { createContext, useContext, FC } from "react";
import { RootStore } from "stores/RootStore";
import * as React from 'react';


const rootStore = new RootStore()

const RootStoreContext = createContext<RootStore>(rootStore)

export function useRootStore() {
    const context = useContext(RootStoreContext);
    if (context === undefined) {
        throw new Error("useRootStore must be used within RootStoreProvider");
    }

    return context;
}

type Props = {
    children?: React.ReactNode
};


export const RootStoreProvider: FC<Props> = ({ children }) => {
    return (
        < RootStoreContext.Provider value={rootStore} >
            {children}
        </RootStoreContext.Provider>
    )
}

export default RootStoreContext
