
import { API, Auth } from "aws-amplify";
import { CurrentUser, ILinkSummary, IUser, IUserOverview, LinkSummary, UserOverview } from "types";


export class UserService {

    constructor() {

    }

    async retrieveUserProfile(email: string): Promise<IUser | undefined> {

        const apiName = 'helloworld';
        const path = '/user';

        console.log(email)
        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            },
            body: {
                "email": email
            }
        };

        let result = await API.post(apiName, path, myInit);



        console.log('api result')
        console.log(result)
        let user: IUser | undefined = CurrentUser.FromJson(result['user'])
        return new Promise((resolve, reject) => {
            resolve(user)
        })

    }

    async retrieveUserOverview(userId: string): Promise<IUserOverview | undefined> {

        const apiName = 'helloworld';
        const path = `/user/${userId}/overview`;

        const myInit = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
            }
        };

        let result = await API.get(apiName, path, myInit);

        console.log('api result')
        console.log(result)
        let user: IUserOverview | undefined = UserOverview.FromJson(result)
        return new Promise((resolve, reject) => {
            resolve(user)
        })
    }
}