import React from "react";
import { Amplify, Auth, API } from "aws-amplify";
import {
  AmplifyProvider,
  Authenticator,
  Button,
  components,
  Flex,
  Heading,
  Image,
  Text,
  Theme,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import aws_exports from "./aws-exports";

import "@aws-amplify/ui-react/styles.css";


import { withAuthenticator } from '@aws-amplify/ui-react';
import CustomLoginForm from './MyAuthenticator';

// const App: React.FC = () => {
//   return (
//     <>
//       <div>Hello, World!</div>
//       <AmplifySignOut />
//     </>
//   );
// };





import { useRootStore } from "context/RootStoreProvider";
import { MainLayout } from "views/MainLayout";
import { BrowserRouter } from 'react-router-dom';

aws_exports['aws_cloud_logic_custom'].push({
  "endpoint": "https://n1panjkha0.execute-api.eu-central-1.amazonaws.com/dev-sum-dev/",
  "name": "helloworld",
  "region": "eu-central-1"
})

let configure = Amplify.configure(aws_exports);
//console.log(configure)

// let apiObject = {
//   "endpoint": "https://imae6cbsmb.execute-api.eu-central-1.amazonaws.com/mytestexp",
//   "name": "testenvapi",
//   "region": "eu-central-1"
// }

// configure.aws_cloud_logic_custom.push(apiObject)

const App = () => {

  let rootStore = useRootStore()

  const callApi = async () => {
    const apiName = 'helloworld';
    const path = '/hellowithauth';
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    };



    let result = await API.get(apiName, path, myInit);
    console.log(result)
  }


  const callLinkSummary = async () => {
    const apiName = 'helloworld';
    const path = '/linksummary/create';
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      },
      body: {
        "email": "stefan.hartmann@outlook.com",
        "url": "https://github.blog/2019-03-29-leader-spotlight-erin-spiceland/"
      }
    };


    let result = await API.post(apiName, path, myInit);
    console.log(result)
  }

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          {/* <Image
            alt="Amplify logo"
            src="https://docs.amplify.aws/assets/logo-dark.svg"
          /> */}
          <Heading level={1} color="#1976d2">
            Link Summary Login
          </Heading>
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    }
  }

  const { tokens } = useTheme()

  const theme: Theme = {
    name: "cra-my-theme",
    tokens: {
      colors: {
        font: {
          primary: { value: "#008080" },
        },
        brand: {
          primary: {
            '10': { value: "#1976d2" },
            '80': { value: "#1976d2" },
            '90': { value: "#1976d2" },
            '100': { value: "#1976d2" },
          },
        },

      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: "#1976d2"
              },
            },
            _hover: {
              color: {
                value: "#1976d2"
              },
            },
            _active: {
              color: {
                value: "#1976d2"
              },
            },
          },
        },
      },
    },

  };


  // console.log(rootStore)


  return (
    <BrowserRouter>
      <AmplifyProvider theme={theme} >
        <Authenticator
          components={components}

        >
          {({ signOut, user }) => (


            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              textAlign="center"
            >
              {/* <View width="100%">
              <Image src={logo} alt="logo" width={240} height={240} />
            </View> */}

              {user && (
                // signOut: ((data?: AuthEventData | undefined) => void
                <MainLayout email={user?.attributes?.email} signOut={signOut} />

              )}

              {/* {user && (
              <View width="100%">
                <Text>Call function</Text>
                <Button onClick={callApi}>
                  <Text>Call Backend</Text>
                </Button>
              </View>
            )}

            {user && (
              <View width="100%">
                <Text>Call Link Summary</Text>
                <Button onClick={callLinkSummary}>
                  <Text>Call Link Summary</Text>
                </Button>
              </View>
            )}

            <View width="100%">
              <Text>
                Edit <code>src/App.tsx</code> and save to reload.
              </Text>
            </View> */}
            </Flex>
          )}

        </Authenticator>
      </AmplifyProvider >
    </BrowserRouter>
  );
};

// export default withAuthenticator(App, {
//   usernameAlias: 'username',
//   signUpConfig: {
//     hiddenDefaults: ['phone_number'],
//   },
//   includeGreetings: false,
//   authenticatorComponents: [CustomLoginForm],
// });
export default App;
