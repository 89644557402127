import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from 'components/Copyright';
import { Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, IconButton, IconButtonProps, Pagination, Paper, styled } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsightsIcon from '@mui/icons-material/Insights';
import { borderRadius, width } from '@mui/system';
import { useRootStore } from 'context/RootStoreProvider';
import { ILinkSummary } from 'types';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { MoreVertOutlined } from '@mui/icons-material';
import { red } from '@mui/material/colors';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ObservedLinkSummaryListItem } from './LinkSummaryListItem/LinkSummaryListItem';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


type ActionsActiveProject = {
    icon: React.ReactNode
    actionType: string
    onClick: (type: string, id: string) => void
}

const LinkSummaryList = observer(() => {

    const rootStore = useRootStore()
    const navigate = useNavigate()

    const [page, setPage] = React.useState<number>(1);
    const itemsPerPage = 4;

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    const createLinkSummary = () => {
        navigate("/create")
    }

    console.log('Active Link Summaries')
    console.log(rootStore.linkSummaryStore.linkSummaries)


    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    
                    height: '100%'
                }}
            >
                
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                my: 1,
                                mx: 0,
                                py: 1,
                                px: 0,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography component="h1" textAlign="left" variant="h5">
                                Link Summaries
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Pagination count={Math.ceil(rootStore.linkSummaryStore.linkSummaries.length / itemsPerPage)} page={page} onChange={handlePageChange} />
                        </Box>
                    </Grid>
                    <Grid item container spacing={1}>

                        {rootStore.linkSummaryStore.linkSummaries.length > 0 && 
                        //     rootStore.linkSummaryStore.linkSummaries.map((linkSummary: ILinkSummary, index) => {
                        //     return (<Grid item xs={12} lg={6} key={linkSummary.guid}>
                        //         <ObservedLinkSummaryListItem  item={linkSummary} />
                        //         </Grid>)
                        // })
                        rootStore.linkSummaryStore.linkSummaries
                            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                            .map((linkSummary) => (
                                <Grid item xs={12} lg={6} key={linkSummary.guid}>
                                    <ObservedLinkSummaryListItem  item={linkSummary} />
                                </Grid>
                            ))
                        } 
                    

                    </Grid>
                    <Grid item>
                        <Box sx={{ m: 1}}>
                            <Pagination count={Math.ceil(rootStore.linkSummaryStore.linkSummaries.length / itemsPerPage)} page={page} onChange={handlePageChange} />
                        </Box>
                    </Grid>
                    
                    
                </Grid>
                
                
                
            </Box>
        </Container>
    );
})

export {
    LinkSummaryList
}