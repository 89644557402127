// import { string } from 'string';

import { Constants } from "helper/Constants";

export interface ILinkSummary {
    guid: string;
    userId: string;
    title: string;
    url: string;
    date: Date;
    summary: string;
    keywords: string[];
    image: string | undefined;
    isPublic: boolean;
    timeCreated: Date;
    timeModified: Date;
    unread: boolean
    pinned: boolean
}

export interface IUser {
    guid: string;
    name: string;
    email: string;
    plan: string;
}

export interface IUserOverview {
    count: number;
    mostRecentDate: Date;
    oldestSummary: Date;
    latestModification: Date;
}


// export class Keyword implements IKeyword {
//     constructor(public id: string, public value: string) { }

//     static FromJson(json: any): IKeyword {
//         return new Keyword(json.id, json.value);
//     }

// }

export class LinkSummary implements ILinkSummary {
    constructor(
        public guid: string,
        public userId: string,
        public title: string,
        public url: string,
        public date: Date,
        public summary: string,
        public keywords: string[],//IKeyword[],
        public image: string | undefined,
        public isPublic: boolean,
        public timeCreated: Date,
        public timeModified: Date,
        public unread: boolean,
        public pinned: boolean
    ) {
        this.guid = guid
        this.userId = userId
        this.title = title
        this.url = url
        this.date = date
        this.summary = summary
        this.keywords = keywords
        this.image = image
        this.isPublic = isPublic
        this.timeCreated = timeCreated
        this.timeModified = timeModified
        this.unread = unread
        this.pinned = pinned

    }

    static FromJson(json: any): LinkSummary {

        let unread = true
        if (Constants.LINK_SUMMARY_UNREAD in json) {
            unread = json[Constants.LINK_SUMMARY_UNREAD]
        }

        console.log(unread)
        return new LinkSummary(
            json.guid,
            json.userId,
            json.title,
            json.url,
            json.date,
            json.summary,
            //json.keywords.map((k: any) => Keyword.FromJson(k)),
            json.keywords,
            json.image,
            json.public,
            json.timeCreated,
            json.timeModified,
            unread,
            json.pinned
        );
    }
}


export class CurrentUser implements IUser {

    constructor(
        public guid: string,
        public name: string,
        public email: string,
        public plan: string
    ) {

    }

    public static FromJson(json: any): IUser | undefined {
        console.log(json)

        if ((Constants.USER_EMAIL in json) === false) {
            return undefined
        }
        if ((Constants.USER_GUID in json) === false) {
            return undefined
        }
        if ((Constants.USER_NAME in json) === false) {
            return undefined
        }
        if ((Constants.USER_PLAN in json) === false) {
            return undefined
        }
        if ((Constants.USER_CREATED in json) === false) {
            return undefined
        }
        if ((Constants.USER_MODIFIED in json) === false) {
            return undefined
        }

        return new CurrentUser(json[Constants.USER_GUID],
            json[Constants.USER_NAME],
            json[Constants.USER_EMAIL],
            json[Constants.USER_PLAN]
        )
    }
}


export class UserOverview implements IUserOverview {

    constructor(
        public count: number,
        public mostRecentDate: Date,
        public oldestSummary: Date,
        public latestModification: Date,
    ) {

    }

    public static FromJson(json: any): IUserOverview | undefined {
        
        return new UserOverview( json.count,
                new Date(json.mostRecentDate),
                new Date(json.oldestSummary),
                new Date(json.latestModification)
        )
    }
}