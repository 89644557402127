

export class Constants {
    public static USER_EMAIL: string = "email"
    public static USER_GUID: string = "guid"
    public static USER_NAME: string = "name"
    public static USER_PLAN: string = "plan"
    public static USER_CREATED: string = "time_created"
    public static USER_MODIFIED: string = "time_modified"


    public static LINK_SUMMARIES_RESPONSE: string = "link_summaries"
    public static LINK_SUMMARY_UNREAD: string = "unread"
}