import { LinkSummaryService } from "service/LinkPreviewService";
import { ILinkSummary, LinkSummary } from "types";
import { IClearableStore } from "./StoreHelper";

import { action, makeObservable, observable, computed, makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";

export class LinkSummaryDetailStore implements IClearableStore {

    linkSummaryService: LinkSummaryService

    _selectedLinkSummary: ILinkSummary | undefined

    @observable
    _summary: string

    @observable
    _keywords: string[]

    @observable
    _note: string

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this)
        this.linkSummaryService = new LinkSummaryService()
        this._selectedLinkSummary = undefined

        this._summary = ""
        this._keywords = []
        this._note = ""
    }

    
    async updateSummary( linkSummaryId: string) {
        try {
            if (this.rootStore.userStore.currentUser) {
                let userId = this.rootStore.userStore.currentUser.guid
                let summary: string | undefined = await this.linkSummaryService.generateSummary(userId, linkSummaryId)
                
                if (summary) {
                    this.setSummary(summary)
                    if (this._selectedLinkSummary) {
                        this._selectedLinkSummary.summary = summary
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    @action
    async updateKeywords( linkSummaryId: string) {
        try {
            if (this.rootStore.userStore.currentUser) {
                let userId = this.rootStore.userStore.currentUser.guid
                let keywords: string [] | undefined = await this.linkSummaryService.generateKeywords(userId, linkSummaryId)
                
                if (keywords) {
                    let s = new Set(this.keywords)
                    for (let keyword of keywords) {
                        s.add(keyword)
                    }

                    let updateKeywords = Array.from(s)
                    this.setKeywords(updateKeywords)
                    if (this._selectedLinkSummary) {
                        this._selectedLinkSummary.keywords = keywords
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    async updateLinkSummaryKeywords( ) {
        try {
            if (this.rootStore.userStore.currentUser) {
                let userId = this.rootStore.userStore.currentUser.guid
                if (this._selectedLinkSummary) {
                    let linkSummaryId: string = this._selectedLinkSummary.guid
                    let result = await this.linkSummaryService.updateKeywords(userId, linkSummaryId, this._selectedLinkSummary.keywords)
                    console.log('updateLinkSummaryKeywords')
                    console.log(result)
                }
            }
        } catch(error) {
            console.log(error)
        }
    }

    @action
    setLinkSummary(linkSummary: ILinkSummary) {
        this._selectedLinkSummary = linkSummary
        this._summary = this._selectedLinkSummary.summary
        this._keywords = this._selectedLinkSummary.keywords
    }

    @action
    setSummary(summary: string) {
        this._summary = summary
    }

    @action
    setKeywords(keywords: string[]) {
        this._keywords = keywords
        if (this._selectedLinkSummary) {
            this._selectedLinkSummary.keywords = Object.assign(this._keywords)
        }
    }

    @action
    addKeyword(keyword: string) {
        this._keywords.push(keyword)
        if (this._selectedLinkSummary) {
            this._selectedLinkSummary.keywords = Object.assign(this._keywords)
        }
    }

    get keywords() : string [] {
        return this._keywords
    }

    get summary() : string {
        return this._summary
    }

    clear(): void {
        this.linkSummaryService = new LinkSummaryService()
        this._selectedLinkSummary = undefined
        this._summary = ""
        this._keywords = []
    }
}

/*
    '{"_id": null, "count": 5, "mostRecentDate": "2023-01-12T07:36:32.118000", "oldestSummary": "2019-03-29T16:00:49", "latestModification": "2023-03-10T21:52:14.212000"}'
*/